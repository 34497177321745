import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./capabilities.scss"
import Service from "./Service"
import ServiceNavigation from "./servicenavigation"

const Capabilities = ({ className }) => {
  const { t } = useTranslation("services", { keyPrefix: "Capabilities" })

  const services = [
    {
      title: t("Custom Software Development"),
      text: t("We offer full software design"),
      iconClassName: "b-icon-7",
      modalTitle: t("Learn more about B2B B2C B2B2C"),
      xs: 12,
      sm: 12,
      md: 10,
      lg: 10,
      xl: 10,
    },
    {
      title: t("Mobile Platforms"),
      text: t(
        "BTS has in-depth experience in developing applications for the most popular mobile platforms"
      ),
      iconClassName: "b-icon-6",
      modalTitle: t("Learn more about Mobile Platforms"),
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      title: t("User Experience"),
      text: t(
        "Our User Experience team covers the 4 steps of UX and can also design the UI"
      ),
      iconClassName: "b-icon-5",
      modalTitle: t("Learn more about UX / UI"),
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      title: t("Machine Learning"),
      text: t("We specialize in enriching existing text data with public data"),
      iconClassName: "b-icon-2",
      modalTitle: t("Learn more about Machine Learning"),
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      title: t("Technical Support"),
      text: t("We are equipped to offer"),
      iconClassName: "b-icon-3",
      modalTitle: t("Learn more about Technical Support"),
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
    },
    {
      title: t("DevSecOps"),
      text: t("We provide DevSecOps managed services"),
      iconClassName: "b-icon-4",
      modalTitle: t("Learn more about DevSecOps"),
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
      xl: 6,
    },
  ]

  return (
    <section
      id="capabilities"
      className={(className ? className + " " : "") + "capabilities"}
    >
      <Container fluid>
        <div className="max-container">
          <ServiceNavigation></ServiceNavigation>

          <Row className="parent-div justify-content-around">
            {services.map(service => {
              return (
                <Col
                  key={service.title}
                  className="capa-products-pad"
                  xs={service.xs}
                  sm={service.sm}
                  md={service.md}
                  lg={service.lg}
                  xl={service.xl}
                >
                  <Service
                    title={service.title}
                    text={service.text}
                    iconClassName={service.iconClassName}
                    modalTitle={service.modalTitle}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default Capabilities
