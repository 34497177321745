import "./aboutyou.scss"
import * as React from "react"
import { Container, Col, Row } from "react-bootstrap"
import ButtonApplyNow from "../layout/buttonapplynow"
import ButtonContact from "../layout/buttoncontact"
import { useTranslation } from "gatsby-plugin-react-i18next"

const AboutYou = ({ className }) => {
  const { t } = useTranslation("common", { keyPrefix: "FindDreamJob" })
  return (
    <section
      className={(className ? className + " " : "") + "section-about-you"}
    >
      <Container fluid>
        <Row className="row-dream-job">
          <Col className="col-dream-job top-button-container" md={6}>
            <div className="button-container">
              <h3>{t("Find Your Dream Job")}</h3>
              <ButtonApplyNow
                formVariant="join"
                arrow={false}
                job={t("Dream Job")}
                title={[t("Let's connect!"), t("Join our Talent Network")]}
              >
                {t("Tell Us About You")}
              </ButtonApplyNow>
            </div>
            <div className="image-container elipse-1"></div>
          </Col>
          <Col className="col-dream-job top-simple-image-container" md={5}>
            <div className="image-container elipse-2"></div>
          </Col>
        </Row>

        <Row className="row-contact-us">
          <Col className="col-contact-us top-simple-image-container" md={5}>
            <div className="image-container elipse-3"></div>
          </Col>
          <Col className="col-contact-us top-button-container" md={6}>
            <div className="image-container elipse-4"></div>
            <div className="button-container">
              <h3>{t("Got a Project")}</h3>
              <ButtonContact title={t("Contact Us")} closeLabel={t("Close")}>
                {t("Contact Us")}
              </ButtonContact>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutYou
