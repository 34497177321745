import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col } from "react-bootstrap"
import ButtonContact from "../layout/buttoncontact"
import "./capabilities.scss"

type ServiceProps = {
  title: string
  text: string
  iconClassName: string
  modalTitle: string

}

const Service = ({
  title,
  text,
  iconClassName,
  modalTitle
}: ServiceProps) => {
  const { t } = useTranslation("services", { keyPrefix: "Capabilities" })
  const modalButtonContent = `${t("Learn More")} ➜`
  const buttonAriaLabel =`${t("Learn More")} ${t("about")} ${title}`

  return (
      <div className="capa-products bg-icon-corner">
        <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={`icon-corner ${iconClassName}`}></div>
          {title}
        </Col>
        <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
          {text}
        </Col>
        <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
          <ButtonContact
            title={modalTitle}
            formVariant="green"
            variant="link"
            closeLabel={t("Close")}
            className=""
            buttonClassName=""
            buttonAriaLabel={buttonAriaLabel}
          >
            {modalButtonContent}
          </ButtonContact>
        </Col>
      </div>

  )
}

export default Service
