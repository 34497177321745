/*
* PROPS
* className: String
* title: Array[String]
* children: String
* arrow: Boolean
*/
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { Button, Modal } from 'react-bootstrap';
import ApplyNowForm from "./applynowform";
import "./buttonapplynow.scss";

const ButtonApplyNow = ({ 
      className,
      buttonClassName = "apply-now",
      title = ["Let's connect!","Join our Talent Network"], 
      formVariant = "default",
      children = "Apply Now", 
      arrow = true,
      variant = "success",
      job,
      ariaLabel
    }) => {
  const [fullscreen, setFullscreen] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation("common", { keyPrefix: "FindDreamJob" });
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <div className={(className ? className + " " : "") + "button-apply-modal"}>
        <Button aria-label={ariaLabel} key="0" variant={variant} className={(arrow ? "btn-arrow ": "") + buttonClassName} onClick={() => handleShow('xxl-down')}>
          {children}
          { arrow ? <span className="icon-arrow-right"></span> : "" }
        </Button>
        <Modal
          className="apply-now-modal"
          size="lg"
          show={show}
          //sdialogClassName={(location.modalClassName ? location.modalClassName : "")}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton closeLabel={t("Close")} className={ formVariant !== 'join' ? 'small': null }>
            <Modal.Title>
            <div className="wrapper-logo d-lg-none">
                <StaticImage
                  className="d-xl-none"
                  layout="fullWidth"
                  //width={87}
                  alt="I am BTS"
                  src="../../images/icons/i-am-bts.svg"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ApplyNowForm title={title} formVariant={formVariant} job={job}></ApplyNowForm>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ButtonApplyNow
