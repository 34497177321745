import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import './sayabout.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SayAbout = ({ className }) => {
  const { t } = useTranslation('index', { keyPrefix: 'SayAbout' });
  return (
    <section id="say-about" className={(className ? className + ' ' : '') + 'say-about'}>
      <Container fluid="xl">
        <Row>
          <Col className="col-container" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="max-ourcase">
              <h1 className="max-ourcase-title header-square blue md-large lg-large">
                {t('What Our Client Say About Our Services')}
              </h1>
              <div className="row text-container">
                <div className="brackets-say col-md-1"></div>
                <p className="col-md-11">
                  {t(
                    '“Our partnership with BTS has been fantastic. They have shouldered the load of recruiting talented engineers who have been able to join our internal team and produce outstanding results. As our business has expanded, they have been able to quickly find team members who are the right fit. They have taken the time to get to know us and discover the type of engineer we are looking for. We will continue to partner with BTS, and we highly recommend them to other development teams looking to grow.”',
                  )}
                </p>
              </div>
              <div className="avatar col-11">
                <div className="image"></div>
                <span>
                  John Atkinson<br></br>CTO, AxisCare
                </span>
                <div className="axis-care"></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SayAbout;
