import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import "./joinbtsmodal.scss"

const JoinbtsModal = () => {

    const { t } = useTranslation("common", { keyPrefix: "joinBts" });
    return (
        <section className="section-joinbts-modal">
            <div className="div-call">
                <div className="container-slogan">
                    <StaticImage
                        className="icon-joinbts"
                        width={74}
                        alt="bg Join BTS Family"
                        src="../../images/icons/i-am-bts.svg"
                        formats={["auto", "webp", "avif"]}
                    />
                    <h3 className="text-white fw-semi-bold">{t("Join the BTS Family")}</h3>
                </div>
                <div className="container-phrase d-none d-md-block">
                    <StaticImage
                        className="icon-quotes"
                        width={85}
                        alt="Quotes"
                        src="../../images/refer/quotes.png"
                        formats={["auto", "webp", "avif"]}
                    />
                    <br></br>
                    <h3 className="text-white fw-semi-bold">
                        {t("Passion is not what")} <br></br> {t("you're good at.")}
                        <br></br><br></br>
                        {t("It's what you")} <br></br>
                        {t("enjoy the most.")}
                    </h3>
                </div>
            </div>
        </section>
    )
}

export default JoinbtsModal
